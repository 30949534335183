import api, { postLogin } from "../api";
import { Module } from "vuex";
import { RootState } from ".";
import { Notification } from "element-ui";
import { Route } from "vue-router";
import router from "@/router";

const sessionModule: Module<SessionState, RootState> = {
  namespaced: true,
  state: {
    env: "",
    customer: "",
    user: {
      id: "",
      email: "",
      roles: [],
      createdAt: "",
    },
    daysLeft: undefined,
    componentsLeft: undefined,
    realms: [],
    wizard: "",
    demo: {},
    uniqueComponents: undefined,
    lastLogin: undefined,
    lastDbUpdate: undefined,
    isAuthenticated: false,
    isOemUser: false,
    isAdmin: false,
    limitReached: false,
    initialized: false,
    version: process.env.VERSION || "n/a",
  },
  actions: {
    async initialize({ dispatch, commit, state }) {
      const { data } = await api.get("/session");
      commit("setSession", data);
      commit("initialize", true);
      dispatch("devices/loadAll", null, { root: true });

      if (state.lastLogin) {
        let localDate = new Date(state.lastLogin).toLocaleString("default");

        if (process.env.VUE_APP_ENV === "mindsphere") {
          setTimeout(
            () =>
              Notification.success({
                title: "Last Login",
                message: "The last login to your account was on " + localDate + ".",
                duration: 5000,
                position: "bottom-right",
              }),
            100
          );
        } else {
          setTimeout(
            () =>
              Notification.success({
                title: "Last Login",
                message: "The last login to your account was on " + localDate + ".",
                duration: 5000,
              }),
            100
          );
        }
      }

      if (state.lastDbUpdate) {
        let localDate = new Date(state.lastDbUpdate).toLocaleString("default");

        if (process.env.VUE_APP_ENV === "mindsphere") {
          setTimeout(
            () =>
              Notification.info({
                title: "Security Vulnerability Data",
                message: "Last updated on " + localDate + ".",
                type: "info",
                duration: 5000,
                position: "bottom-right",
              }),
            100
          );
        } else {
          setTimeout(
            () =>
              Notification.info({
                title: "Security Vulnerability Data",
                message: "Last updated on " + localDate + ".",
                duration: 5000,
              }),
            100
          );
        }
      }

      if (state.daysLeft >= 0) {
        if (process.env.VUE_APP_ENV === "mindsphere") {
          setTimeout(
            () =>
              Notification.info({
                title: "Demo Account",
                message:
                  "You have " +
                  state.daysLeft +
                  "/30 days left and are currently using " +
                  state.componentsLeft +
                  "/50 components for this Demo.",
                duration: 5000,
                position: "bottom-right",
              }),
            100
          );
        } else {
          setTimeout(
            () =>
              Notification.info({
                title: "Demo Account",
                message:
                  "You have " +
                  state.daysLeft +
                  "/30 days left and are currently using " +
                  state.componentsLeft +
                  "/50 components for this Demo.",
                duration: 5000,
              }),
            100
          );
        }
      }
    },

    async login({ commit }, payload: LoginPayload) {
      try {
        const { username, password } = payload;

        await postLogin(username, password);

        await commit("initialize", false);
        if (payload.redirectTo) {
          router.push(payload.redirectTo);
        }
      } catch (err) {
        if (err.message.includes("timeban")) {
          if (process.env.VUE_APP_ENV === "mindsphere") {
            Notification.error({
              title: "Login blocked",
              message:
                "Oops, you are blocked for 10 minutes because you tried to login 5 times with wrong credentials.",
              duration: 5000,
              position: "bottom-right",
            });
          } else {
            Notification.error({
              title: "Login blocked",
              message:
                "Oops, you are blocked for 10 minutes because you tried to login 5 times with wrong credentials.",
              duration: 5000,
            });
          }
        } else {
          if (process.env.VUE_APP_ENV === "mindsphere") {
            Notification.error({
              title: "Login failed",
              message: "Oops, could not authenticate with these credentials.",
              duration: 5000,
              position: "bottom-right",
            });
          } else {
            Notification.error({
              title: "Login failed",
              message: "Oops, could not authenticate with these credentials.",
              duration: 5000,
            });
          }
        }

        throw err;
      }
    },

    async logout() {
      try {
        await api.post("/logout");
      } catch (err) {}

      setTimeout(() => {
        window.location.href = "/";
      }, 1000);
    },

    async selectRealm(_, payload) {
      await api.post("/session/realm", payload);

      // Do a complete reload of the application
      window.location.href = "/";
    },
  },
  mutations: {
    setSession(state, payload) {
      Object.assign(state, payload);
    },
    initialize(state, payload) {
      state.initialized = payload;
    },
  },
};

export { sessionModule as default };

interface SessionState {
  env: string;
  customer: string;
  user: {
    id: string;
    email: string;
    roles: string[];
    createdAt: string;
  };
  realms: {
    id: string;
    name: string;
  }[];
  wizard: string;
  demo: {};
  daysLeft?: number;
  componentsLeft?: number;
  isAuthenticated: boolean;
  isOemUser: boolean;
  uniqueComponents: string;
  isAdmin: boolean;
  limitReached: boolean;
  initialized: boolean;
  lastLogin?: string;
  lastDbUpdate?: string;
  version?: string;
}

interface LoginPayload {
  username: string;
  password: string;
  redirectTo?: Route;
}
