import api from "../api";
import { RootState } from ".";
import { Module } from "vuex";
import buildURL from "axios/lib/helpers/buildURL";
import { MessageBox, Loading, Message } from "element-ui";

const vulnerabilitiesModule: Module<VulnerabilitiesState, RootState> = {
  namespaced: true,
  state: {
    filters: {
      title: "",
      componentId: "",
      devices: [],
      priorities: [],
      statuses: [],
      dateFrom: "",
      dateTo: "",
      plannedFix: undefined,
      customCvss: undefined,
    },
    tableSort: {
      prop: "details.publish_date",
      order: "descending",
    },
    showFilter: false,
    currentPage: 1,
    perPage: 20,
    currentTotal: 0,
    rawVulnerabilities: [],
    selected: [],
  },
  actions: {
    async exportList({ getters }) {
      let queryParams;
      queryParams = getters.queryParams;
      // we`re resetting this damn thingy, because we want all vulns and not page limited vulns
      queryParams.limit = 0;

      const url = buildURL("/ivm-api/vulnerabilities/export", queryParams);

      window.open(url);
    },
    async toggleShowFilter({ commit, state }, payload?: boolean) {
      if (typeof payload === "undefined") {
        payload = !state.showFilter;
      }

      commit("toggleFilter", Boolean(payload));
    },
    setFilter({ commit, dispatch }, payload: ChangeFilterPayload) {
      commit("changeFilter", payload);
      dispatch("loadPage");
    },
    async loadPage({ commit, getters }, payload?: { currentPage: number; perPage: number }) {
      if (payload) {
        commit("setPagination", payload);
      }

      const { data, headers } = await api.get("/vulnerabilities", {
        params: getters.queryParams,
      });

      commit("setRawVulnerabilities", {
        data,
        total: Number(headers["pagination-total"]),
      });
    },
    async loadTasklist() {
      const { data, headers } = await api.get("/vulnerabilities", {
        params: { plannedFix: 1 },
      });
      return data;
    },
    async saveStatus(state, payload) {
      let ids, status;
      if (Array.isArray(payload)) {
        ids = payload.map(({ id }) => id);
        status = payload[0].status;
      } else {
        ids = [payload.id];
        status = payload.status;
      }

      await api.put("/vulnerabilities/status", { ids, statusToSet: status });
    },
    async saveCustomCvss(state, payload) {
      let { id, customCvss } = payload;

      await api.post("/vulnerabilities/customCvss", {
        id,
        customCvss,
      });
    },
    async setComment(state, { id, comment }) {
      await api.put("/vulnerabilities/comment", { id, comment });
    },
    async setPlannedFix(state, { id, plannedFix }) {
      const date = plannedFix
        ? `${plannedFix.getFullYear()}-${plannedFix.getMonth() + 1}-${plannedFix.getDate()}`
        : null;

      await api.put("vulnerabilities/plannedfix", { id, plannedFix: date });
    },
    async saveAssignee(state, { id, assigneeId }) {
      await api.put("vulnerabilities/assignee", { id, assignee: assigneeId });
    },
    async applySort({ commit, dispatch }, payload) {
      const prop = payload.prop || "details.publish_date";
      const order = payload.order || "descending";

      const sortPayload: VulnerabilitiesState["tableSort"] = { prop, order };

      commit("setSort", sortPayload);

      dispatch("loadPage");
    },
    selectItems({ commit }, payload: App.Models.AppVulnerability[]) {
      commit("selectItems", payload.slice());
    },
    async printVulnerability({ dispatch }, id) {
      const { data } = await api.post(`/vulnerabilities/${id}/print`);
      const token = data.token;
      let status;

      const loading = Loading.service({
        fullscreen: true,
        text: "Please wait while PDF is being generated. It may take some minutes to generate large reports.",
      });

      try {
        if (token) {
          while (
            (status = await dispatch("printVulnerabilityCheck", {
              id,
              token,
            })) === "waiting"
          ) {
            await dispatch("printVulnerabilityCheck", { id, token });
          }

          if (status === "ready") {
            const filePath = `/ivm-api/vulnerabilities/${id}/print?token=${token}&download=1`;
            const a = document.createElement("a");

            a.href = filePath;
            a.style.display = "none";
            document.body.appendChild(a);
            a.target = "_blank";
            a.setAttribute("download", `Vulnerability-${id}.pdf`);
            a.click();
            loading.close();
            document.body.removeChild(a);
            return;
          }
        }
      } catch (err) {}
      loading.close();

      MessageBox.alert(
        `Could not download report. Please contact tenant administrator or try again later.`,
        "Device Report Error"
      );
    },
    async printVulnerabilityCheck(store, { id, token }) {
      return new Promise((res, rej) => {
        setTimeout(async () => {
          try {
            const { data } = await api.post(`/vulnerabilities/${id}/print`, {}, { params: { token } });

            res(data.status);
          } catch (err) {
            rej(err);
          }
        }, 1000);
      });
    },
  },
  mutations: {
    toggleFilter(state, payload: boolean) {
      state.showFilter = payload;
    },
    changeFilter(state, payload: ChangeFilterPayload) {
      if (payload.reset) {
        const defaultFilter = {
          title: "",
          componentId: "",
          devices: [],
          priorities: [],
          statuses: [],
          dateFrom: "",
          dateTo: "",
          plannedFix: undefined,
          customCvss: undefined,
        };

        state.filters = { ...defaultFilter };
      }

      if (payload.field) {
        state.filters[payload.field as any] = payload.value;
      }
    },
    setSort(state, payload: VulnerabilitiesState["tableSort"]) {
      state.tableSort = payload;
    },
    setPagination(state, payload: { currentPage: number; perPage: number }) {
      state.currentPage = Number(payload.currentPage) || 1;

      if (payload.perPage) {
        state.perPage = Number(payload.perPage) || 20;
      }
    },
    selectItems(state, vulnerabilities: App.Models.AppVulnerability[]) {
      state.selected = vulnerabilities;
    },
    setRawVulnerabilities(state, { data, total }) {
      state.rawVulnerabilities = data.slice();
      state.currentTotal = total;
    },
  },
  getters: {
    vulnerabilities(state) {
      return state.rawVulnerabilities;
    },
    queryParams(state) {
      const limit = state.perPage;
      const offset = Number((state.currentPage - 1) * state.perPage);
      let listIds, date;

      if (state.filters.devices.length) {
        listIds = state.filters.devices.map(({ id }) => id).slice();
      }

      if (state.filters.dateFrom && state.filters.dateTo) {
        const { dateFrom, dateTo } = state.filters;

        date = [dateFrom, dateTo].join(" - ");
      }

      const statePlanned = state.filters.plannedFix && (state.filters.plannedFix as any).value;

      const plannedFix = typeof statePlanned === "undefined" ? undefined : Number(statePlanned);
      const searchText = state.filters.title || undefined;
      const componentId = state.filters.componentId || undefined;
      const status = state.filters.statuses.map(({ value }) => value);
      const priority = state.filters.priorities.map(({ value }) => value);
      const { prop: sortBy, order: sortDir } = state.tableSort;

      return {
        searchText,
        listIds,
        date,
        offset,
        componentId,
        status,
        priority,
        limit,
        plannedFix,
        sortBy,
        sortDir,
      };
    },
    filterItemsCount(state) {
      let count = state.filters.devices.length + state.filters.priorities.length + state.filters.statuses.length;

      if (state.filters.title) count++;
      if (state.filters.componentId) count++;
      if (state.filters.dateFrom && state.filters.dateTo) count++;

      return count;
    },
  },
};

export { vulnerabilitiesModule as default };

interface VulnerabilitiesState {
  filters: {
    componentId: string;
    title: string;
    devices: {
      id: string;
    }[];
    priorities: {
      value: number;
    }[];
    statuses: {
      value: number;
    }[];
    plannedFix?: boolean;
    dateFrom: string;
    dateTo: string;
    customCvss: number;
  };
  tableSort: {
    prop: string;
    order: "descending" | "ascending";
  };
  selected: App.Models.AppVulnerability[];
  showFilter: boolean;
  currentPage: number;
  currentTotal: number;
  perPage: number;
  rawVulnerabilities: any[];
}

interface ChangeFilterPayload {
  field: keyof VulnerabilitiesState["filters"];
  value: any;
  reset?: boolean;
}
