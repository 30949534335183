import Vue from "vue";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./plugins";

import {
  initSessionRouteGuard,
  isAuthenticatedRouteGuard,
  showLangingPageWhenDemo,
  redirectWhenDemoTrialEnded,
} from "./lib/security-guards";

Vue.config.productionTip = false;

router.beforeEach(initSessionRouteGuard(store));
router.beforeEach(showLangingPageWhenDemo(store));
router.beforeEach(isAuthenticatedRouteGuard(store));
router.beforeEach(redirectWhenDemoTrialEnded(store));

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

Vue.component("button-counter", {
  data: function () {
    return {
      count: 0,
    };
  },
  template: '<button v-on:click="count++">You clicked me {{ count }} times.</button>',
});

store.dispatch("devices/loadAll");

if (process.env.VUE_APP_ENV === "mindsphere") {
  const head = document.querySelector("head");
  const script = document.createElement("script");

  script.onload = () => {
    setTimeout(() => {
      (window as any)["_mdsp"].init({
        title: "Industrial Security Manager",
        initialize: true,
        showLegal: true,
        disableHomelink: false,
        polyfills: { promise: false },
      });
    }, 10);
  };

  script.src = "https://static.eu1.mindsphere.io/osbar/v4/js/main.min.js";
  head && head.appendChild(script);
}
