import api from "../api";
import { MessageBox, Loading } from "element-ui";
import { Module } from "vuex";
import { RootState } from "./index";

const devicesStore: Module<DevicesState, RootState> = {
  namespaced: true,
  state: {
    rawDevices: [],
    rawComponents: [],
    selectedDevices: [""],
    treeDelimeter: "/",
    filter: "",
  },
  actions: {
    async loadAll({ commit, dispatch }) {
      const { data } = await api.get("/devices");
      commit("setRawDevices", data);

      dispatch("loadComponents");
    },
    selectOnlyDevice({ commit, dispatch }, deviceId) {
      commit("selectDevice", "");

      if (deviceId) {
        commit("selectDevice", deviceId);
      }

      dispatch("loadComponents");
    },
    toggleSelection({ commit, dispatch, state }, payload: { deviceId: string; state?: boolean }) {
      if (typeof payload.state === "undefined") {
        payload.state = state.selectedDevices.indexOf(payload.deviceId) === -1;
      }

      const mutation = payload.state ? "selectDevice" : "deselectDevice";
      commit(mutation, payload.deviceId);

      dispatch("loadComponents");
    },
    async loadComponents({ commit, dispatch, state }) {
      const listIds = state.selectedDevices.filter((id) => !!id);

      const { data } = await api.get("/components", {
        params: {
          listIds,
        },
      });

      commit("setRawComponents", data);
    },
    async saveDevice({ dispatch }, { device, event }) {
      try {
        const { data } = await api.post("/devices", { device, event });

        dispatch("selectOnlyDevice", data.id);
      } catch (err) {
        MessageBox.alert(`List with name "${device.name}" already exists.`, "List Error");
      }

      dispatch("loadAll");
    },
    async removeDevice({ dispatch }, device) {
      await api.delete("/devices/" + device.id);

      dispatch("selectOnlyDevice", "");
      dispatch("loadAll");
    },
    async removeSelectedDevices({ dispatch }, selectedDevices) {
      for (const deviceId of selectedDevices) {
        await api.delete("/devices/" + deviceId);
      }

      dispatch("selectOnlyDevice", "");
      dispatch("loadAll");
    },
    async saveComponent({ dispatch }, { component, toArchive }) {
      try {
        const listId = component.listId ? component.listId : component.list.id;

        await api.post("/components", { component, toArchive }, { params: { listId } });
      } catch (error) {
        const serverData = error.response && error.response.data;

        MessageBox.alert(serverData.message, "Component Error");
      }

      dispatch("loadAll");
    },
    async removeComponent({ dispatch }, { component, toArchive }) {
      const componentId = component.id;

      await api.delete("/components/" + componentId, { data: { componentId, toArchive } });
      dispatch("loadAll");
    },
    async exportSelectedDevices({ state }, monitored) {
      let hasComponents;

      if (monitored === "monitored") {
        hasComponents = state.rawComponents.filter((component) => component.certComponentId > 0).length > 0;
      } else {
        hasComponents = state.rawComponents.filter((component) => component.certComponentId <= 0).length > 0;
      }

      if (hasComponents) {
        const listIds = state.selectedDevices.join(",");

        const link = `/ivm-api/components/export?type=${monitored}&listIds=${listIds}`;

        window.open(link);
      } else {
        await MessageBox.alert(`There are no ${monitored} components in the selected list. Aborting export.`, {
          type: "info",
          customClass: "el-message-box__device-import-dialog",
        });
      }
    },

    async saveComponentComment({ dispatch }, { id, comment }) {
      try {
        // await api.put("/components/comment", { id, comment });
      } catch (error) {
        const serverData = error.response && error.response.data;

        MessageBox.alert(serverData.message, "Component Error");
      }

      dispatch("loadAll");
    },

    async exportExtendedSelectedDevices(
      { state, dispatch },
      payload: { devices: string[]; additional: { date: Date; description: string; exportHierarchical: boolean } }
    ) {
      const devices = payload.devices;
      const { date, description, exportHierarchical } = payload.additional;

      try {
        const response = await api.post(
          "/devices/exportextended",
          {
            devices,
            date,
            description,
            exportHierarchical,
          },
          {
            responseType: "blob",
            timeout: 60000,
          }
        );

        const a = document.createElement("a");
        a.href = window.URL.createObjectURL(new Blob([response.data]));
        a.setAttribute("download", extractFilenameFromHeaders(response.headers));
        a.style.display = "none";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } catch (err) {
        MessageBox.alert(`Could not export. ${err.message}.`, "Export Error");
      }
    },

    async exportReport({ state, dispatch }, entry) {
      const listIds = state.selectedDevices.join(",");
      const data = await buildData(entry, listIds);

      const token = data.token;
      let status;

      const loading = Loading.service({
        fullscreen: true,
        text: "Please wait while report is being generated. It may take some minutes to generate large reports.",
      });

      try {
        if (token) {
          while ((status = await dispatch("checkReport", token)) === "waiting") {
            await dispatch("checkReport", token);
          }

          if (status === "ready") {
            const filePath = `/ivm-api/devices/report?token=${token}&download=1`;
            const a = document.createElement("a");

            a.href = filePath;
            a.style.display = "none";
            document.body.appendChild(a);
            a.target = "_blank";
            a.setAttribute("download", "Vulnerability_Management_Report.pdf");
            a.click();
            loading.close();
            document.body.removeChild(a);
            return;
          }
        }
      } catch (err) {}

      loading.close();

      MessageBox.alert(
        `Could not download report. Please contact tenant administrator or try again later.`,
        "List Report Error"
      );
    },
    async checkReport(store, token) {
      return new Promise((res, rej) => {
        setTimeout(async () => {
          try {
            const { data } = await api.post("/devices/report", {}, { params: { token } });

            res(data.status);
          } catch (err) {
            rej(err);
          }
        }, 1000);
      });
    },
    async importReport({ dispatch }, importData) {
      const { components, errors } = importData;

      let componentsData = [],
        errorData = [];

      if (components && components.length) {
        componentsData = await Promise.all(
          components.map(async (component: Component) => {
            const { vendor, name, version } = component;
            const listName = component.list.name;
            const componentText = `${vendor} ${name} ${version}`;

            return { listName, component: componentText };
          })
        );
      }

      if (errors && errors.length) {
        errorData = await Promise.all(
          errors.map(async (component: Component) => {
            const { vendor, name, version } = component;
            const listName = component.list.name;
            const componentText = `${vendor} ${name} ${version}`;

            return { listName, component: componentText };
          })
        );
      }

      return { componentsData, errorData };
    },
    setFilter({ commit, dispatch }, value) {
      commit("setFilter", value);
      dispatch("selectOnlyDevice", "");
      dispatch("loadAll");
    },
  },
  mutations: {
    setRawDevices(state, payload) {
      state.rawDevices = payload.slice();
    },
    setRawComponents(state, payload) {
      state.rawComponents = payload.slice();
    },
    selectDevice(state, deviceId) {
      if (deviceId !== "") {
        state.selectedDevices = state.selectedDevices.filter((id) => !!id).concat([deviceId]);
      } else {
        state.selectedDevices = [""];
      }
    },
    deselectDevice(state, deviceId) {
      state.selectedDevices = state.selectedDevices.filter((item) => item !== deviceId);

      if (state.selectedDevices.length === 0) {
        state.selectedDevices.push("");
      }
    },
    setFilter(state, value) {
      state.filter = value;
    },
  },
  getters: {
    allDevices(state): SelectableDevice[] {
      return state.rawDevices
        .map((deviceRecord) => ({
          ...deviceRecord,
          selected: state.selectedDevices.indexOf(deviceRecord.id) > -1,
        }))
        .filter((data) => !state.filter || data.name.toLowerCase().includes(state.filter.toLowerCase()));
    },
    devices(_, getters): SelectableDevice[] {
      const allDevices: SelectableDevice[] = getters.allDevices;

      return allDevices.filter(({ id }) => !!id);
    },
    components(state) {
      return state.rawComponents.map((componentRecord) => ({
        ...componentRecord,
        device: componentRecord.list.name,
      }));
    },
    treeDevices(state, getters): DeviceTree {
      const devices: SelectableDevice[] = getters.devices;
      const cache: { [x: string]: DeviceTreeItem | DeviceTreeItemSimple } = {};

      return devices
        .sort()
        .reduce((acc: (DeviceTreeItem | DeviceTreeItemSimple)[], deviceRecord: SelectableDevice) => {
          if (!deviceRecord.id) {
            return acc;
          }

          const nameParts = deviceRecord.name
            .split(state.treeDelimeter)
            .map(trim)
            .filter((val) => !!val);

          const treeCandidate: (DeviceTreeItem | DeviceTreeItemSimple)[] = nameParts.map((label, index, arr) => {
            const leaf = index === arr.length - 1;

            const cacheKey = nameParts.slice(0, index).concat([label]).join("/") + (leaf ? "" : "/");

            const candidate: DeviceTreeItem | DeviceTreeItemSimple = (!leaf && cache[cacheKey]) || {
              id: "",
              label,
              children: [],
            };

            cache[cacheKey] = candidate;

            return candidate;
          });

          const lastChildIndex = treeCandidate.length - 1;
          const lastChild = treeCandidate[lastChildIndex];

          treeCandidate[lastChildIndex] = {
            ...lastChild,
            ...deviceRecord,
            leaf: true,
          };

          treeCandidate.reverse().forEach((child, index) => {
            const parent = treeCandidate[index + 1];

            if (parent) {
              if (child.label === nameParts[nameParts.length - 1]) {
                child.children = [];
              }

              if (parent.children.indexOf(child) === -1) {
                parent.children.push(child);
              }
              parent.id = [parent.id, child.id].filter((i) => !!i).join(",");
              parent.leaf = false;
            }
          });

          acc.push(treeCandidate[treeCandidate.length - 1]);

          return acc;
        }, [])
        .filter((device, index, all) => all.indexOf(device) === index);
    },
  },
};

export { devicesStore as default };

function trim(s: string): string {
  return s.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, "");
}

function extractFilenameFromHeaders(headers) {
  const contentDisposition = headers["content-disposition"];
  const matches = /filename="([^"]+)"/.exec(contentDisposition);

  return matches ? matches[1] : "IVM_Export.zip";
}

async function buildData(entry, listIds) {
  if (entry) {
    const response = await api.post(
      `/devices/report?listIds=${listIds}&customer=${entry.customer}&codeword=${entry.codeword}&product=${entry.product}`
    );
    return response.data;
  }

  const response = await api.post(`/devices/report?listIds=${listIds}`);
  return response.data;
}

interface DevicesState {
  rawDevices: App.Models.AppDevice[];
  rawComponents: any[];
  selectedDevices: string[];
  treeDelimeter: string | RegExp;
  filter: string;
}

interface SelectableDevice extends App.Models.AppDevice {
  selected: boolean;
}

interface DeviceTreeItem extends SelectableDevice, DeviceTreeItemSimple {}

interface DeviceTreeItemSimple {
  leaf?: boolean;
  id: string;
  label: string;
  children: (DeviceTreeItem | DeviceTreeItemSimple)[];
}

type DeviceTree = (DeviceTreeItemSimple | DeviceTreeItem)[];

type Component = any;
