<template>
  <span class="menu" ref="menu">
    <!-- Left Menu -->
    <el-menu class="el-menu__left" :router="true" :default-active="$route.name" mode="horizontal" ref="mainMenu">
      <!-- Dashboard -->
      <el-menu-item index="dashboard" :route="{ name: 'dashboard' }">
        <el-icon name="data-analysis" />
        Dashboard
      </el-menu-item>

      <!-- Devices -->
      <el-menu-item index="devices" :route="{ name: 'devices' }">
        <el-icon name="document-copy" />
        Inventory
      </el-menu-item>

      <!-- Vulnerabilities -->
      <el-menu-item index="vulnerabilities" :route="{ name: 'vulnerabilities' }">
        <el-icon name="tickets" />
        Vulnerabilities
      </el-menu-item>
      <el-menu-item index="tasklist" :route="{ name: 'tasklist' }">
        <el-icon name="document-checked" />
        Tasklist
      </el-menu-item>
    </el-menu>

    <!-- Right Menu -->
    <el-menu class="el-menu__right" :router="true" :default-active="$route.name" mode="horizontal" ref="rightMenu">
      <!-- User -->
      <el-submenu v-if="realms.length > 1" index="user">
        <template slot="title">
          <el-icon name="user" />
          {{ user.email }} ({{ customer.name }})
        </template>
        <el-menu-item
          v-for="realm in realms"
          :index="'user-' + realm.name"
          :key="realm.id"
          @click.native.capture="selectRealm(realm)"
          :disabled="!realm.enabled"
        >
          <el-icon name="office-building" />
          {{ realm.name }}
        </el-menu-item>
      </el-submenu>
      <el-menu-item v-else-if="!isMindsphere" index="user-account" :route="{ name: 'user-account' }">
        <el-icon name="user" />
        {{ user.email }}
      </el-menu-item>

      <!-- Settings / Support expanded when screen is big enough -->
      <!-- Settings -->
      <el-submenu v-if="!smallScreen" index="settings" :popper-append-to-body="true">
        <template slot="title">
          <el-icon name="setting" />
          Settings
        </template>
        <el-menu-item-group title="Profile">
          <el-menu-item index="settings-account" v-if="!isMindsphere" :route="{ name: 'settings-account' }">
            <el-icon name="lock" />
            Account
          </el-menu-item>
          <el-menu-item index="settings-subscription" :route="{ name: 'settings-subscription' }">
            <el-icon name="message" />
            Subscription
          </el-menu-item>
        </el-menu-item-group>
        <el-menu-item-group v-if="user.roles.includes('ROLE_ADMIN')" title="Account Management">
          <el-menu-item index="settings-realms" v-if="env !== 'demo'" :route="{ name: 'settings-realms' }">
            <el-icon name="office-building" />
            Customers
          </el-menu-item>
          <el-menu-item index="settings-users" :route="{ name: 'settings-users' }">
            <el-icon name="user" />
            Users
          </el-menu-item>
          <el-menu-item v-if="env !== 'demo'" index="settings-users-api" :route="{ name: 'settings-users-api' }">
            <el-icon name="key" />
            API Users
          </el-menu-item>
        </el-menu-item-group>
        <el-menu-item-group v-if="user.roles.includes('ROLE_ADMIN')" title="Database">
          <el-menu-item index="settings-vulnerability-data" :route="{ name: 'settings-vulnerability-data' }">
            <el-icon name="coin" />
            Vulnerability Data
          </el-menu-item>
        </el-menu-item-group>
        <el-menu-item-group v-if="user.roles.includes('ROLE_ADMIN')" title="Report">
          <el-menu-item index="settings-report" :route="{ name: 'settings-report' }">
            <el-icon name="notebook-2" />
            Report
          </el-menu-item>
        </el-menu-item-group>
        <el-menu-item-group v-if="user.roles.includes('ROLE_ADMIN')" title="Audit">
          <el-menu-item index="settings-audit" :route="{ name: 'settings-audit' }">
            <el-icon name="bell" />
            Audit
          </el-menu-item>
        </el-menu-item-group>
      </el-submenu>

      <!-- Support -->
      <el-submenu v-if="!smallScreen" index="support" :popper-append-to-body="true">
        <template slot="title">
          <el-icon name="help" />
          Support
        </template>
        <el-menu-item index="">
          <a href="mailto:ivm.industry@siemens.com">
            <el-icon name="message" />
            E-Mail
          </a>
        </el-menu-item>
        <el-menu-item index="">
          <a target="_blank" href="/IVM_Userdocumentation.pdf">
            <el-icon name="document"></el-icon>
            User Documentation
          </a>
        </el-menu-item>
        <el-menu-item index="" disabled>
          <el-icon name="monitor" />
          Unique Components: {{ uniqueComponents }}
        </el-menu-item>
        <el-menu-item index="" disabled>
          <el-icon name="info" />
          Version {{ version }}
        </el-menu-item>
      </el-submenu>

      <!-- TODO: -Unused- Settings / Support collapsed within submenu if screen is too small -->
      <el-submenu v-if="smallScreen" index="more">
        <template slot="title">
          <el-icon name="menu" />
          More
        </template>

        <!-- Settings -->
        <el-submenu index="settings" :popper-append-to-body="true">
          <template slot="title">
            <el-icon name="setting" />
            Settings
          </template>
          <el-menu-item-group title="Profile">
            <el-menu-item index="settings-account" v-if="!isMindsphere" :route="{ name: 'settings-account' }">
              <el-icon name="lock" />
              Account
            </el-menu-item>
            <el-menu-item index="settings-subscription" :route="{ name: 'settings-subscription' }">
              <el-icon name="message" />
              Subscription
            </el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group v-if="user.roles.includes('ROLE_ADMIN')" title="Account Management">
            <el-menu-item index="settings-realms" v-if="env !== 'demo'" :route="{ name: 'settings-realms' }">
              <el-icon name="office-building" />
              Customers
            </el-menu-item>
            <el-menu-item index="settings-users" :route="{ name: 'settings-users' }">
              <el-icon name="user" />
              Users
            </el-menu-item>
            <el-menu-item v-if="env !== 'demo'" index="settings-users-api" :route="{ name: 'settings-users-api' }">
              <el-icon name="key" />
              API Users
            </el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group v-if="user.roles.includes('ROLE_ADMIN')" title="Database">
            <el-menu-item index="settings-vulnerability-data" :route="{ name: 'settings-vulnerability-data' }">
              <el-icon name="coin" />
              Vulnerability Data
            </el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group v-if="user.roles.includes('ROLE_ADMIN')" title="Report">
            <el-menu-item index="settings-report" :route="{ name: 'settings-report' }">
              <el-icon name="notebook-2" />
              Report
            </el-menu-item>
          </el-menu-item-group>
          <el-menu-item-group v-if="user.roles.includes('ROLE_ADMIN')" title="Audit">
            <el-menu-item index="settings-audit" :route="{ name: 'settings-audit' }">
              <el-icon name="bell" />
              Audit
            </el-menu-item>
          </el-menu-item-group>
        </el-submenu>

        <!-- Support -->
        <el-submenu index="support" :popper-append-to-body="true">
          <template slot="title">
            <el-icon name="help" />
            Support
          </template>
          <el-menu-item index="">
            <a target="_blank" href="mailto:ivm.industry@siemens.com">
              <el-icon name="message" />
              Mail
            </a>
          </el-menu-item>
          <el-menu-item index="">
            <a target="_blank" href="/IVM_Userdocumentation.pdf">
              <el-icon name="document"></el-icon>
              User Documentation
            </a>
          </el-menu-item>
          <el-menu-item index="" disabled>
            <el-icon name="monitor" />
            Unique Components: {{ uniqueComponents }}
          </el-menu-item>
          <el-menu-item index="" disabled>
            <el-icon name="info" />
            Version {{ version }}
          </el-menu-item>
        </el-submenu>
      </el-submenu>

      <!-- Logout -->
      <el-menu-item v-if="!isMindsphere" index="account-logout" :route="{ name: 'account-logout' }">
        <el-icon name="switch-button" style="color: #f56c6c; margin: 0" />
      </el-menu-item>
    </el-menu>
  </span>
</template>

<style lang="scss">
.el-menu.el-menu--horizontal {
  border-bottom: none;
}

.menu {
  display: flex;
  justify-content: space-between;
  border-bottom: solid 1px #e6e6e6;
  min-width: 1200px;
}

// @media screen and (max-width: 1200px) {
//   .el-menu--horizontal > .el-menu-item {
//     font-size: 13px !important;
//   }

//   .el-menu--horizontal > .el-submenu .el-submenu__title {
//     font-size: 13px !important;
//   }
// }

.el-menu__left {
  display: flex;
  height: 65px !important;
  align-self: flex-start;
  justify-content: flex-start;
  overflow: hidden;
  min-width: 500px;
}

.el-menu__right {
  display: flex;
  height: 65px !important;
  align-self: flex-end;
  justify-content: flex-end;
  overflow: hidden;
  min-width: 700px;
}

.el-menu-item {
  padding: 0 10px !important;
}

.el-submenu__title {
  padding: 0 10px !important;
}

.el-menu--horizontal > ul > * > a {
  color: inherit;
  text-decoration: none;
}

.el-menu.el-menu--horizontal {
  border-bottom: none !important;
}
</style>

<script>
import Vue from "vue";
import { mapState, mapActions } from "vuex";

export default Vue.extend({
  name: "HeaderLayout",
  props: {
    windowSize: { type: Object },
  },
  data() {
    return {
      width: 0,
      height: 0,
      smallScreen: false,
      overflow: false,
      menuSpanWidth: 0,
      menuWidth: 0,
      menuWidthMain: 0,
      menuWidthRight: 0,
      linksWidth: 0,
      linksWidthMain: 0,
      linksWidthRight: 0,
    };
  },

  computed: {
    ...mapState("session", ["user", "realms", "customer", "env", "version", "uniqueComponents"]),
    isMindsphere() {
      return process.env.VUE_APP_ENV === "mindsphere";
    },
  },

  watch: {
    windowSize: {
      deep: true,
      immediate: true,
      async handler() {
        await this.$nextTick();
        // console.log("windowSize:", this.windowSize.height, "x", this.windowSize.width);
      },
    },
  },

  methods: {
    ...mapActions("session", ["selectRealm"]),
    calculateMenuWidth() {
      // TODO
      // Settings / Support menu should collapse if screen size is too small, set smallScreen to true
      console.log("=== calculateMenuWidth");

      // calculate menuWidth, repeatedly if windowSize changes
      // if either left or right menus would overflow within it`s block, set
      const menuSpan = this.$refs.menu;
      const mainMenu = this.$refs.mainMenu;
      const rightMenu = this.$refs.rightMenu;

      this.menuSpanWidth = menuSpan.clientWidth;
      this.menuWidthMain = mainMenu.$el.clientWidth + 1;
      this.menuWidthRight = rightMenu.$el.clientWidth + 1;
      this.menuWidth = this.menuWidthMain + this.menuWidthRight;

      console.log("menu span", this.menuSpanWidth);
      console.log("menu main", this.menuWidthMain);
      console.log("menu right", this.menuWidthRight);
      console.log("menu total", this.menuWidth);

      console.log("--------------------------------");
      if (this.menuWidthMain < this.linksWidthMain) {
        console.log("menu main < links main");
      } else if (this.menuWidthRight < this.linksWidthRight) {
        console.log("menu right < links right");
        this.smallScreen = true;
      } else if (this.menuSpanWidth < this.linksWidth) {
        console.log("menu span < links");
      } else {
        console.log("ok");

        // this.smallScreen = false;
      }
      console.log("--------------------------------");
    },
    calculateLinksWidth() {
      // TODO
      // Settings / Support menu should collapse if screen size is too small
      console.log("=== calculateLinksWidth");

      // calculate linksWidth for the full, non-collapsed menu
      this.linksWidthMain = 0;
      this.linksWidthRight = 0;
      const mainMenu = this.$refs.mainMenu;
      const rightMenu = this.$refs.rightMenu;

      mainMenu.$children.forEach((child) => {
        this.linksWidthMain += child.$el.clientWidth;
      });
      this.linksWidthMain += 1;

      rightMenu.$children.forEach((child) => {
        this.linksWidthRight += child.$el.clientWidth;
      });
      this.linksWidthRight += 1;

      this.linksWidth = this.linksWidthMain + this.linksWidthRight;

      console.log("links total", this.linksWidth);
      console.log("links main", this.linksWidthMain);
      console.log("links right", this.linksWidthRight);
    },
  },

  mounted() {},

  updated() {
    // TODO
    // this.calculateLinksWidth();
    // this.calculateMenuWidth();
  },
});
</script>
