import axios from "axios";
import he from "he";

const apiClient = axios.create({
  baseURL: "/ivm-api",
  withCredentials: true,
  headers: {
    "Cache-Control": "no-cache",
  },
});

export default apiClient;

export async function postLogin(username: string, password: string) {
  try {
    const { data } = await apiClient.post<App.Models.AccountUser[]>("/login", {
      username,
      password,
    });

    return data;
  } catch (err) {
    throw new Error(err.response.data);
  }
}

export async function getAllAccountUsers() {
  const { data } = await apiClient.get<App.Models.AccountUser[]>("/account-users/account");

  return data;
}

export async function getAllApiUsers() {
  const { data } = await apiClient.get<App.Models.AccountUser[]>("/account-users/api");

  return data;
}

export async function getAccountUserById(id: string) {
  const { data } = await apiClient.get<App.Models.AccountUser>(`/account-users/${id}`);

  return data;
}

export async function getAllRealmUsers() {
  const { data } = await apiClient.get<App.Models.AccountRealm>("/account-users/realm");

  return data;
}

export async function getAllAccountRealms() {
  const { data } = await apiClient.get<App.Models.AccountRealm[]>("/account-realms");

  return data;
}

export async function getRealmById(id: string) {
  const { data } = await apiClient.get<App.Models.AccountRealm>(`/account-realms/${id}`);

  return data;
}

export async function saveRealm(realm: Partial<App.Models.AccountRealm>) {
  const { data } = await apiClient.post<App.Models.AccountRealm>("/account-realms", realm);

  return data;
}

export async function deleteRealm(id: string) {
  const { data } = await apiClient.delete(`/account-realms/${id}`);

  return data;
}

export async function saveUser(
  user: Partial<App.Models.AccountUser>,
  userCurrentState: Partial<App.Models.AccountUser>,
  env: string
) {
  const { data } = await apiClient.post<App.Models.AccountUser>(`/account-users`, user);

  if (user.enabled && !userCurrentState.enabled && env === "demo") {
    await apiClient.post<App.Models.AccountUser>(`/demo/enable`, user);
  }

  return data;
}

export async function extendUser(user: Partial<App.Models.AccountUser>, env: String) {
  const { data } = await apiClient.post<App.Models.AccountUser>(`/account-users/extend`, user);

  return data;
}

export async function deleteUser(user: Partial<App.Models.AccountUser>) {
  const { data } = await apiClient.delete(`/account-users/${user.id}`);

  return data;
}

export async function getComponent(componentId: string) {
  const { data } = await apiClient.get(`/components/${componentId}`);

  return data;
}

export async function getAudits(offset: number, pageSize: number) {
  const params = {
    offset: offset,
    pageSize: pageSize,
  };
  const { data } = await apiClient.get<App.Models.AppAudit[]>(`/settings/audit`, {
    params,
  });

  return data;
}

export async function getVulnerability(vulnerabiliyId: string) {
  const { data: vulnerability } = await apiClient.get(`/vulnerabilities/${vulnerabiliyId}`);

  vulnerability.details.title = he.decode(vulnerability.details.title);
  vulnerability.details.description = he.decode(vulnerability.details.description);
  vulnerability.details.vendor_affected_components = he.decode(vulnerability.details.vendor_affected_components);
  vulnerability.details.cve_references = vulnerability.details.cve_references.map((cve) => {
    if (cve.number < 1000) {
      cve.number = "0" + cve.number;
    }
    return cve;
  });

  return vulnerability;
}

export async function getTimeSeriesData(cve: string) {
  const { data } = await apiClient.get(`/vulnerabilities/cve/${cve}`);

  return data;
}

export async function getHurricaneData(cve: string) {
  const { data } = await apiClient.get(`/vulnerabilities/hcn/${cve}`);

  return data;
}

export async function getComponentVendors(vendor: string, name: string) {
  const { data } = await apiClient.get(`/components/autocomplete`, {
    params: {
      name,
      vendor,
      type: "vendor",
    },
  });

  return data;
}

export async function getComponentNames(vendor: string, name: string) {
  const { data } = await apiClient.get(`/components/autocomplete`, {
    params: {
      name,
      vendor,
      type: "name",
    },
  });

  return data;
}

export async function getComponentVersions(vendor: string, name: string, version?: string) {
  let response;

  if (version) {
    response = await apiClient.get(`/components/autocomplete`, {
      params: {
        name,
        vendor,
        version,
        type: "version",
      },
    });
  } else {
    response = await apiClient.get(`/components/autocomplete`, {
      params: {
        name,
        vendor,
        type: "version",
      },
    });
  }

  return response.data;
}

export async function componentMappingResolve(id: string, remember: boolean) {
  const { data } = await apiClient.put(`/components/mapping/${id}/resolve`, {
    remember,
  });

  return data;
}
export async function componentMappingReject(id: string, remember: boolean) {
  const { data } = await apiClient.put(`/components/mapping/${id}/reject`, {
    remember,
  });

  return data;
}

export async function createManualMapping(mapping) {
  const { data } = await apiClient.post(`/components/mapping`, mapping);

  return data;
}

export async function createComponentAlias(component, target) {
  const { data } = await apiClient.post("/components/alias", {
    component,
    target,
  });

  return data;
}

export async function getComponentById(componentId: string | number): Promise<string> {
  const { data } = await apiClient.get(`/components/${componentId}`);

  return data;
}

export async function getAuditsCount(): Promise<number> {
  const { data } = await apiClient.get<number>(`/settings/audit/count`);

  return data;
}

export async function accountResetPasswordRequest(email: string): Promise<string> {
  const { data } = await apiClient.post(`/account/reset-password-request`, {
    email,
  });

  return data;
}

export async function accountResetPasswordConfirm(token: string, password: string): Promise<string> {
  const { data } = await apiClient.post(`/account/reset-password-confirm`, {
    token,
    password,
  });

  return data;
}

export async function getVersion(): Promise<string> {
  const { data } = await apiClient.get(`/settings/version`);

  return data;
}
