<template>
  <span>
    <el-row class="el-row__footer">
      <el-col :span="24">Copyright © Siemens AG, 2016-{{ getYear }}</el-col>
    </el-row>
    <el-row class="el-row__footer">
      <el-col :span="4">
        <a href="https://new.siemens.com/global/en/general/legal.html" target="_blank">Imprint</a>
      </el-col>
      <el-col :span="4">
        <a href="https://www.siemens.com/global/en/general/privacy-notice.html" target="_blank">Data Privacy</a>
      </el-col>
      <el-col :span="4">
        <a href="https://www.siemens.com/global/en/general/cookie-notice.html" target="_blank">Cookie Policy</a>
      </el-col>
      <el-col :span="4">
        <a href="https://www.siemens.com/global/en/general/terms-of-use.html" target="_blank">Terms of Use</a>
      </el-col>
      <el-col :span="4">
        <a href="/ReadMe_OSS.html" target="_blank">OSS</a>
      </el-col>
    </el-row>
  </span>
</template>

<style lang="scss">
.l-default__footer a {
  color: inherit;
  text-decoration: none;
}

.l-default__footer a:hover {
  color: #333;
}

.el-row__footer {
  font-size: 14px;
  color: #999;
  line-height: 20px;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
<style scoped lang="scss">
@media screen and (max-width: 900px) {
  .el-col {
    font-size: 12px;
  }
}

@media screen and (max-width: 700px) {
  .el-col {
    font-size: 10px;
  }
}

@media screen and (max-width: 500px) {
  .el-col {
    font-size: 8px;
  }
}
</style>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "FooterLayout",
  props: {
    footerClass: {
      type: String,
      required: true,
    },
  },
  computed: {
    getYear() {
      return new Date().getFullYear();
    },
  },
});
</script>
