<template>
  <div id="app" :class="{ 'app--with-mindbar': isMindsphere }">
    <component class="app-layout" :is="layoutComponent"></component>
  </div>
</template>

<style lang="scss">
html,
body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
    "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: $--color-text-regular;
}

#app {
  min-height: 100%;
  max-height: 100%;
  background-color: $--background-color-base;
  overflow: auto;
}

#app .app-layout {
  height: 100%;
}

#app.app--with-mindbar {
  order: 3;
  min-height: calc(100% - 44px);
  max-height: calc(100% - 44px);
}
</style>
<style src="normalize.css/normalize.css"></style>
<style src="element-theme-chalk/lib/index.css"></style>
<style src="element-theme-chalk/lib/display.css"></style>
<style src="./assets/styles.css"></style>

<script>
import DefaultLayout from "./layouts/default.vue";
import CenterPageLayout from "./layouts/center-page.vue";
import Vue from "vue";

const validLayouts = ["DefaultLayout", "CenterPageLayout"];
export default Vue.extend({
  components: {
    DefaultLayout,
    CenterPageLayout,
  },
  computed: {
    isMindsphere() {
      return process.env.VUE_APP_ENV === "mindsphere";
    },
    layoutComponent() {
      const { name, meta } = this.$route;

      if (meta && meta.layout) {
        if (process.env.NODE_ENV === "production" || validLayouts.indexOf(meta.layout) > -1) {
          return meta.layout;
        }

        console.warn('Layout "%s" defined in route "%s" is not valid. Using DefaultLayout', meta.layout, name);
      }

      return "DefaultLayout";
    },
  },
});
</script>
