import { Module } from "vuex";
import he from "he";
import { RootState } from "./index";
import apiClient from "../api";

const tasklistStore: Module<TasklistState, RootState> = {
  namespaced: true,
  state: {
    rawTasklist: [],
    rawCounts: [],
  },
  actions: {
    async fetch({ dispatch }) {
      return Promise.all([dispatch("fetchCounts"), dispatch("fetchData")]);
    },
    async fetchCounts({ rootState, commit }) {
      const { data } = await apiClient.get("/devices/tasks");

      commit("setRawCounts", data);
    },
    async fetchData({ rootState, commit }) {
      const listIds = (rootState as any).devices.selectedDevices.slice();

      const params = {
        plannedFix: 1,
        status: [0, 1, 2, 3],
        listIds: void 0,
        limit: 0,
        offset: 0,
      };

      if (listIds.filter((t) => !!t).length > 0) {
        params.listIds = listIds;
      }

      const { data } = await apiClient.get("/vulnerabilities", { params });

      data.map((data) => {
        data.details.title = he.decode(data.details.title);
      });

      commit("setRawTasklist", data);
    },
  },
  mutations: {
    setRawCounts(state, payload) {
      state.rawCounts = payload.slice();
    },
    setRawTasklist(state, payload) {
      state.rawTasklist = payload.slice();
    },
  },
  getters: {
    countsByDevice(state, getters, rootState, rootGetters) {
      const map = new Map();

      let allupcoming = 0,
        alloverdue = 0,
        allclosed = 0,
        allacknowledged = 0,
        allunscheduled = 0;

      const allDevices = rootGetters["devices/allDevices"];

      for (let i = 0; i < allDevices.length; i++) {
        map.set(allDevices[i].id, {
          upcoming: allupcoming,
          overdue: alloverdue,
          closed: allclosed,
          acknowledged: allacknowledged,
          unscheduled: allunscheduled,
        });
      }

      for (let i = 0; i < state.rawCounts.length; i++) {
        const { deviceId, upcoming, overdue, closed, acknowledged, unscheduled } = state.rawCounts[i];

        alloverdue += +overdue;
        allupcoming += +upcoming;
        allclosed += +closed;
        allacknowledged += +acknowledged;
        allunscheduled += +unscheduled;

        map.set(deviceId, {
          upcoming,
          overdue,
          closed,
          acknowledged,
          unscheduled,
        });
      }

      map.set("", {
        upcoming: allupcoming,
        overdue: alloverdue,
        closed: allclosed,
        acknowledged: allacknowledged,
        unscheduled: allunscheduled,
      });

      return map;
    },
    upcoming(state) {
      const date = new Date().setHours(0, 0, 0, 0);

      return state.rawTasklist.filter((v) => {
        return new Date(v.plannedFix).setHours(0, 0, 0, 0) > date && (v.status == 0 || v.status == 1);
      });
    },
    overdue(state) {
      const date = new Date().setHours(0, 0, 0, 0);

      return state.rawTasklist.filter((v) => {
        return new Date(v.plannedFix).setHours(0, 0, 0, 0) <= date && (v.status === 0 || v.status === 1);
      });
    },
    closed(state) {
      return state.rawTasklist.filter((v) => {
        return v.status === 2;
      });
    },
    acknowledged(state) {
      return state.rawTasklist.filter((v) => {
        return v.status === 3;
      });
    },
  },
};

export { tasklistStore as default };

interface TasklistState {
  rawTasklist: Vulnerability[];
  rawCounts: {
    deviceId: string;
    upcoming: number;
    overdue: number;
    closed: number;
    acknowledged: number;
    unscheduled: number;
  }[];
}

interface Vulnerability {
  id: string;
  plannedFix: string;
  status: number;
}
