import Vue from "vue";
import Vuex from "vuex";

import session from "./session";
import devices from "./devices";
import vulnerabilities from "./vulnerabilities";
import dashboard from "./dashboard";
import tasklist from "./tasklist";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    session,
    devices,
    vulnerabilities,
    dashboard,
    tasklist,
  },
});

if (module.hot) {
  // accept actions and mutations as hot modules
  module.hot.accept(["./devices", "./session", "./vulnerabilities", "./dashboard", "./tasklist"], () => {
    // require the updated modules
    // have to add .default here due to babel 6 module output
    const session = require("./session").default;
    const devices = require("./devices").default;
    const vulnerabilities = require("./vulnerabilities").default;
    const dashboard = require("./dashboard").default;
    const tasklist = require("./tasklist").default;
    // swap in the new modules and mutations
    store.hotUpdate({
      modules: {
        session,
        devices,
        vulnerabilities,
        dashboard,
        tasklist,
      },
    });
  });
}

export { store as default };

export interface RootState {}
