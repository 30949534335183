import { Store } from "vuex";
import { NavigationGuard } from "vue-router";

export function initSessionRouteGuard(store: Store<any>): NavigationGuard<any> {
  return async (to, from, next) => {
    if (!store.state.session.initialized) {
      await store.dispatch("session/initialize");
    }

    next();
  };
}

export function isAuthenticatedRouteGuard(store: Store<any>): NavigationGuard<any> {
  return async (to, from, next) => {
    if (to.meta.isPublic) {
      return next();
    }

    if (!store.state.session.isAuthenticated) {
      return next({ name: "account-login", query: { from: to.path } });
    }

    next();
  };
}

export function showLangingPageWhenDemo(store: Store<any>): NavigationGuard<any> {
  return async (to, from, next) => {
    if (to.name === "demo-landing-page") {
      return next();
    }

    if (store.state.session.env === "demo" && !localStorage.demoAccepted) {
      return next({ name: "demo-landing-page" });
    }

    next();
  };
}

export function redirectWhenDemoTrialEnded(store: Store<any>): NavigationGuard<any> {
  return async (to, from, next) => {
    if (to.name === "demo-ended") {
      return next();
    }

    if (
      store.state.session.env === "demo" &&
      store.state.session.isAuthenticated &&
      store.state.session.user.roles.indexOf("ROLE_DEMO_ENDED") > -1
    ) {
      return next({ name: "demo-ended" });
    }

    next();
  };
}
