import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);

export default new Router({
  mode: process.env.VUE_APP_ENV === "mindsphere" ? "hash" : "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
      redirect: { name: "dashboard" },
    },
    /* Dashboard */
    {
      path: "/dashboard",
      name: "dashboard",
      component: () => import("./views/dashboard/Dashboard.vue"),
    },
    /* Devices */
    {
      path: "/devices",
      name: "devices",
      component: () => import("./views/devices/Devices.vue"),
    },
    /* Vulnerabilities */
    {
      path: "/vulnerabilities",
      name: "vulnerabilities",
      component: () => import("./views/vulnerabilities/Vulnerabilities.vue"),
    },
    /* Tasklist */
    {
      path: "/tasklist",
      name: "tasklist",
      component: () => import("./views/tasklist/Tasklist.vue"),
      props: true,
    },
    /* Template */
    {
      path: "/template",
      name: "template",
      component: () => import("./views/template/Template.vue"),
      props: true,
    },
    /* Account */
    {
      path: "/account/login",
      name: "account-login",
      meta: {
        layout: "CenterPageLayout",
        isPublic: true,
      },
      component: () => import("./views/account/AccountLogin.vue"),
    },
    {
      path: "/account/logout",
      name: "account-logout",
      meta: {
        layout: "CenterPageLayout",
        isPublic: true,
      },
      component: () => import("./views/account/AccountLogout.vue"),
    },
    {
      path: "/account/create",
      name: "account-create",
      meta: {
        layout: "CenterPageLayout",
        isPublic: true,
      },
      component: () => import("./views/Placeholder.vue"),
    },
    {
      path: "/account/reset-password",
      name: "account-reset-password",
      meta: {
        layout: "CenterPageLayout",
        isPublic: true,
      },
      component: () => import("./views/account/AccountResetPassword.vue"),
    },
    {
      path: "/account/reset-password-confirm",
      name: "account-reset-password-confirm",
      meta: {
        layout: "CenterPageLayout",
        isPublic: true,
      },
      component: () => import("./views/account/AccountResetPasswordConfirm.vue"),
    },
    {
      path: "/account/reset-password-email",
      name: "account-reset-password-email",
      meta: {
        layout: "CenterPageLayout",
        isPublic: true,
      },
      component: () => import("./views/account/AccountResetPasswordEmail.vue"),
    },
    {
      path: "/account/reset-password-email-confirm",
      name: "account-reset-password-email-confirm",
      meta: {
        layout: "CenterPageLayout",
        isPublic: true,
      },
      component: () => import("./views/account/AccountResetPasswordEmailConfirm.vue"),
    },
    {
      path: "/account/confirm-email",
      name: "account-confirm-email",
      meta: {
        layout: "CenterPageLayout",
        isPublic: true,
      },
      component: () => import("./views/Placeholder.vue"),
    },
    /* Demo */
    {
      path: "/demo/landing-page",
      name: "demo-landing-page",
      meta: {
        layout: "CenterPageLayout",
        isPublic: true,
      },
      component: () => import("./views/demo/DemoLandingPage.vue"),
    },
    {
      path: "/demo/register",
      name: "demo-register",
      meta: {
        layout: "CenterPageLayout",
        isPublic: true,
      },
      component: () => import("./views/demo/DemoRegister.vue"),
    },
    {
      path: "/demo/ended",
      name: "demo-ended",
      meta: {
        layout: "CenterPageLayout",
      },
      component: () => import("./views/demo/DemoEnded.vue"),
    },
    /* Settings */
    {
      path: "/settings",
      name: "settings",
      component: () => import("./views/settings/Settings.vue"),
      children: [
        {
          path: "/settings/vulnerability-data",
          name: "settings-vulnerability-data",
          component: () => import("./views/settings/VulnerabilityData.vue"),
        },
        {
          path: "/settings/vulnerability-data/connection",
          name: "settings-vulnerability-data-connection",
          component: () => import("./views/settings/VulnerabilityDataConnectionOptions.vue"),
        },
        {
          path: "/settings/realms",
          name: "settings-realms",
          component: () => import("./views/settings/Realms.vue"),
        },
        {
          path: "/settings/realms/:id",
          name: "settings-realms-edit",
          component: () => import("./views/settings/RealmsCreateOrEdit.vue"),
        },
        {
          path: "/settings/users",
          name: "settings-users",
          component: () => import("./views/settings/Users.vue"),
        },
        {
          path: "/settings/users/:id",
          name: "settings-users-edit",
          component: () => import("./views/settings/UsersCreateOrEdit.vue"),
        },
        {
          path: "/settings/users-api",
          name: "settings-users-api",
          component: () => import("./views/settings/UsersApi.vue"),
        },
        {
          path: "/settings/users-api/:id",
          name: "settings-users-api-edit",
          component: () => import("./views/settings/UsersApiCreateOrEdit.vue"),
        },
        {
          path: "/settings/account",
          name: "settings-account",
          component: () => import("./views/settings/Account.vue"),
        },
        {
          path: "/settings/account",
          name: "user-account",
          component: () => import("./views/settings/Account.vue"),
        },
        {
          path: "/settings/subscription",
          name: "settings-subscription",
          component: () => import("./views/settings/Subscription.vue"),
        },
        {
          path: "/settings/report",
          name: "settings-report",
          component: () => import("./views/settings/Report.vue"),
        },
        {
          path: "/settings/audit",
          name: "settings-audit",
          component: () => import("./views/settings/Audit.vue"),
        },
        {
          path: "/settings/about",
          name: "settings-about",
          component: () => import("./views/settings/About.vue"),
        },
      ],
    },
    /* Setup Wizard */
    {
      path: "/setup/wizard",
      name: "setup-wizard",
      meta: {
        layout: "CenterPageLayout",
        isPublic: true,
      },
      component: () => import("./views/setup/SetupWizard.vue"),
    },
  ],
});
