import api from "../api";
import { RootState } from ".";
import { Module } from "vuex";

const dashboardModule: Module<DashboardState, RootState> = {
  namespaced: true,
  state: {
    filters: {
      devices: [],
      dateFrom: "",
      dateTo: "",
    },
    showFilter: false,
    showFullscreen: false,
    showFullscreenType: "",
    rawData: {},
  },
  actions: {
    async toggleShowFilter({ commit, state }, payload?: boolean) {
      if (typeof payload === "undefined") {
        payload = !state.showFilter;
      }

      commit("toggleFilter", Boolean(payload));
    },
    setFilter({ commit, dispatch }, payload: ChangeFilterPayload) {
      commit("changeFilter", payload);
      dispatch("loadPage");
    },
    async toggleShowFullscreen({ commit, state }, payload?: boolean) {
      if (typeof payload === "undefined") {
        payload = !state.showFullscreen;
      }

      commit("toggleFullscreen", Boolean(payload));
    },
    setFullscreen({ commit, dispatch }, payload: string) {
      commit("changeFullscreenType", payload);
    },
    async loadPage({ commit, getters }) {
      const { data } = await api.get("/summary", {
        params: getters.queryParams,
      });
      commit("setRawData", { data });
    },
  },
  mutations: {
    toggleFilter(state, payload: boolean) {
      state.showFilter = payload;
    },
    changeFilter(state, payload: ChangeFilterPayload) {
      if (payload.reset) {
        const defaultFilter = {
          devices: [],
          dateFrom: "",
          dateTo: "",
        };

        state.filters = { ...defaultFilter };
      }

      if (payload.field) {
        state.filters[payload.field] = payload.value;
      }
    },
    toggleFullscreen(state, payload: boolean) {
      state.showFullscreen = payload;
    },
    changeFullscreenType(state, payload: string) {
      state.showFullscreenType = payload;
    },
    setRawData(state, { data }) {
      state.rawData = data;
    },
  },
  getters: {
    data(state) {
      return state.rawData;
    },
    queryParams(state) {
      let listIds, date;

      if (state.filters.devices.length) {
        listIds = state.filters.devices.map(({ id }) => id).slice();
      }

      if (state.filters.dateFrom && state.filters.dateTo) {
        const { dateFrom, dateTo } = state.filters;

        date = [dateFrom, dateTo].join(" - ");
      }

      return {
        listIds,
        date,
        status,
      };
    },
  },
};

export { dashboardModule as default };

interface DashboardState {
  filters: {
    devices: {
      id: string;
    }[];
    dateFrom: string;
    dateTo: string;
  };
  showFilter: boolean;
  showFullscreen: boolean;
  showFullscreenType: string;
  rawData: any;
}

interface ChangeFilterPayload {
  field: keyof DashboardState["filters"];
  value: any;
  reset?: boolean;
}
